import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import toast from 'react-hot-toast'
import { claimReward, getDailyCheckin } from '../api'
import { useSelector } from 'react-redux'

const abc = ["one", "two", "three", "four", "five", "six", "seven"]
const DailyCheckin = () => {
    const [data, setData] = useState({})
    const [cardData, setCardData] = useState({})
    const {contract} = useSelector(state => state.user)

    useEffect(() => {
        if (data) {
            let createData = {};
            abc.map(item => {
                if (data[item]?.status) {
                    if (data[item]?.collected) {
                        createData[item] = "Claimed Card"
                    } else {
                        createData[item] = "Active Card"
                    }
                } else {
                    createData[item] = "Upcoming Card"
                }
                if (data[item]?.banned) {
                    createData[item] = "Banned"
                }
                // if (data[item]?.)
                // if (data[item])
            })
            setCardData(createData)
        }
    }, [data])

    const getData = async () => {
        try {
            const { data } = await getDailyCheckin()
            setData(data?.data)
        } catch (err) {
            toast.error(err?.response?.data?.message || err.message)
        }
    }
    useEffect(() => {
        try {
            getData();
        } catch (err) {}
    }, [])

    useEffect(() => {
      console.log(contract)
    }, [contract])
    

    const claim = async () => {
        try {
            const { data } = await claimReward();
            if (data.success) {
                toast.success(data.message);
                setData(data.data)
            }
        } catch (err) {
            toast.error(err?.response?.data?.message || err.message)
        }
    }
    return (
        <>
            <Navbar />
            <div className="MainFix mt-lg-5 mt-3">
                <div className="container">
                    <div className="popUpMainBg">

                        <div className="row d-flex justify-content-between">
                            <div className="col-10 d-flex flex-column justify-content-center align-items-start">
                                <h3 className="cl-M fs-theme">Daily Reward</h3>
                                <span className="f-14 cl-t">Come back every day to get better rewards!</span>
                            </div>
                            {/* <div className="col-2 d-flex justify-content-center align-items-center">
                                <a href="#" className="ms-auto"><i className="far fa-times checkclose cl-t"></i></a>
                            </div> */}
                        </div>


                        <div className="row my-4 my-md-5">
                            <div className="cusGrid">
                                <img src={`/images/Daily_Checkin/${cardData?.one || "Claimed Card"} - Day 1.png`} className="img-fluid weeklyCard" draggable="false" />
                                <img src={`/images/Daily_Checkin/${cardData?.two || "Claimed Card"} - Day 2.png`} className="img-fluid weeklyCard" draggable="false" />
                                <img src={`/images/Daily_Checkin/${cardData?.three || "Claimed Card"} - Day 3.png`} className="img-fluid weeklyCard" draggable="false" />
                                <img src={`/images/Daily_Checkin/${cardData?.four || "Claimed Card"} - Day 4.png`} className="img-fluid weeklyCard" draggable="false" />
                                <img src={`/images/Daily_Checkin/${cardData?.five || "Claimed Card"} - Day 5.png`} className="img-fluid weeklyCard" draggable="false" />
                                <img src={`/images/Daily_Checkin/${cardData?.six || "Claimed Card"} - Day 6.png`} className="img-fluid weeklyCard" draggable="false" />
                                <img src={`/images/Daily_Checkin/${cardData?.seven || "Claimed Card"} - Day 7.png`} className="img-fluid weeklyCard" draggable="false" />
                            </div>
                        </div>


                        <div className="row d-flex justify-content-between">
                            <div className="col-8 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center w-100">
                                {/* <h5 className="cl-gt checkinTimer order-1 order-md-0 mt-3 mt-md-0"><i className="far fa-stopwatch cl-Y me-2 me-lg-3"></i>10h 38m 51s</h5> */}
                                <a href="#" className="ms-md-auto order-0 order-md-1" onClick={claim}><div className="BtnClaim">Claim</div></a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="checkInblackBG checkInblackBGActive"></div> */}
            </div>
        </>
    )
}

export default DailyCheckin