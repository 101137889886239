import React, { useEffect } from 'react'
import { completeTaskById } from '../../../api'

const TaskCard = ({ data, setTasks }) => {
    const completeTaskHandler = async () => {
        try {
            const { data: res } = await completeTaskById(data?.task._id)
            setTasks(res?.data)
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <div className="TaskCardBorder mt-4 " style={{ opacity: data.completed ? 0.5 : 1, cursor: data.completed ? 'not-allowed' : 'pointer' }} onClick={e => completeTaskHandler()}>
            <div className="TaskCard fs-theme d-flex justify-content-between align-items-center">
                <div>
                    <div className="TaskNo">{data?.task.name}</div>
                    <span className="cl-t">{data?.task.description}</span>
                </div>
                <span className="TaskPoints" >{data?.task?.points} pts</span>
            </div>
        </div>
    )
}

export default TaskCard