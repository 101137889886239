import axios from 'axios';

const api = axios.create({
    baseURL: "http://161.35.178.24:5000/api",
    // baseURL: "http://localhost:5000/api",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
    },
})
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if (token && token != 'null') {
            config.headers.Authorization = `Bearer ${token}`
            return config;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


export const registerUser = (data) => api.post('/register', data)
export const getReferAndEarn = () => api.get('/referrals')

export const getLeaderBoard = (data) => api.get('/leaderboard', data)
export const getRank = () => api.get('/getrank')
export const getUserPoints = () => api.get('/getpoints')

export const getAllTasks = (data) => api.get('/getalltask', data)

export const completeTaskById = (id) => api.get(`/completetask/${id}`)

export const spin = (data) => api.post('/spin', data);

export const pointRedeemApi = (data) => api.post('/pointredeem', data)

export const getDailyCheckin = () => api.get('/getCheckin')
export const claimReward = () => api.get('/claimcheckin')



export default api;
