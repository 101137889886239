import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import toast from 'react-hot-toast';
import { Modal } from 'antd';
import { pointRedeemApi } from '../api';

const PointRedeem = () => {
    const [pointRedeem, setpointRedeem] = useState();
    const [pointData, setPointData] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [placeholder, setPlaceholder] = useState("")
    const getRedeem = async () => {
        try {
            const { data } = await pointRedeemApi({ plan: pointRedeem, data: pointData })
            if (data.success) {
                toast.success(data.message)
                setIsOpen(false);
                setPointData("")
                
            } else {
                toast.error(data.message)
                setIsOpen(false);
                setPointData("")
            }
        } catch (err) {
            toast.error(err.response.data.message)
            setIsOpen(false);
            setPointData("")
        }
    }
    useEffect(() => {
        switch (pointRedeem) {
            case "freemint":
                setPlaceholder("Enter your wallet address")
                break;
            case "whitelist":
                setPlaceholder("Enter your wallet address")
                break;
            case "earlysupporter":
                setPlaceholder("Enter your Discord Name")
                break;
            case "ogsupporter":
                setPlaceholder("Enter your Discord Name")
                break;
        }
    }, [pointRedeem])

    return (
        <>
            <Navbar />
            <section className="mrg_t">
                <div className="container">
                    <div className="row d-flex justify-content-evenly align-items-center mb-0 mb-md-5">
                        <div className="col-md-6 col-xl-4 mb-4 mb-md-0 wow fadeInDown" data-wow-delay="1s">
                            <div className="redeemCardMain d-flex flex-column justify-content-center align-items-center">
                                <div className="redeemCard d-flex flex-column justify-content-center align-items-center">
                                    <div className="mainPointRedCardIcon"><img src="/images/White - Zk Realm Logo.png" className="img-fluid pointRedCardIcon" draggable="false" alt="Free mint redeem box" /></div>
                                    <div className="mt-3 fs-theme f-24 cl-t text-center">Free mint redeem box</div>
                                </div>
                                <div className="p-3 text-center" style={{ cursor: "pointer" }} onClick={e => { setpointRedeem('freemint'); setIsOpen(true) }}>
                                    <div className="redPoint">50000</div>
                                    <div className="requires">(Requires points)</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4 mb-4 mb-md-0 wow fadeInDown" data-wow-delay="1.2s">
                            <div className="redeemCardMain d-flex flex-column justify-content-center align-items-center">
                                <div className="redeemCard d-flex flex-column justify-content-center align-items-center">
                                    <div className="mainPointRedCardIcon"><img src="/images/White - Zk Realm Logo.png" className="img-fluid pointRedCardIcon" draggable="false" alt="Free mint redeem box" /></div>
                                    <div className="mt-3 fs-theme f-24 cl-t text-center">Whitelist</div>
                                </div>
                                <div className="p-3 text-center" onClick={e => { setpointRedeem('whitelist'); setIsOpen(true) }}>
                                    <div className="redPoint">20000</div>
                                    <div className="requires">(Requires points)</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row d-flex justify-content-evenly align-items-center">


                        <div className="col-md-6 col-xl-4 mb-4 mb-md-0 wow fadeInDown" data-wow-delay="1.4s">
                            <div className="redeemCardMain d-flex flex-column justify-content-center align-items-center">
                                <div className="redeemCard d-flex flex-column justify-content-center align-items-center">
                                    <div className="mainPointRedCardIcon"><img src="/images/White - Zk Realm Logo.png" className="img-fluid pointRedCardIcon" draggable="false" alt="Free mint redeem box" /></div>
                                    <div className="mt-3 fs-theme f-24 cl-t text-center">Discord role early supporter</div>
                                </div>
                                <div className="p-3 text-center" onClick={e => { setpointRedeem('earlysupporter'); setIsOpen(true) }}>
                                    <div className="redPoint">15000</div>
                                    <div className="requires">(Requires points)</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xl-4 mb-4 mb-md-0 wow fadeInDown" data-wow-delay="1.6s">
                            <div className="redeemCardMain d-flex flex-column justify-content-center align-items-center">
                                <div className="redeemCard d-flex flex-column justify-content-center align-items-center">
                                    <div className="mainPointRedCardIcon"><img src="/images/White - Zk Realm Logo.png" className="img-fluid pointRedCardIcon" draggable="false" alt="Free mint redeem box" /></div>
                                    <div className="mt-3 fs-theme f-24 cl-t text-center">Discord role OG supporter</div>
                                </div>
                                <div className="p-3 text-center" onClick={e => { setpointRedeem('ogsupporter'); setIsOpen(true) }}>
                                    <div className="redPoint">25000</div>
                                    <div className="requires">(Requires points)</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Modal title="Redeem" open={isOpen} onOk={() => getRedeem()} onCancel={() => setIsOpen(false)}>
                <input placeholder={placeholder} className='cusInput w-100 my-2 text-white' onChange={e => setPointData(e.target.value)} value={pointData} />
            </Modal>
        </>
    )
}

export default PointRedeem