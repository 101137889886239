import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { getAllTasks } from '../../api'
import TaskCard from './components/TaskCard'

const Task = () => {
    const [tasks, setTasks] = useState([])
    useEffect(() => {
        (async () => {
            try {
                const { data } = await getAllTasks()
                setTasks(data?.data)
            } catch (err) {
                
            }
        })()
    }, [])
    return (
        <>
            <Navbar />
            <section className="mrg_t">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-12 col-lg-11 col-xl-10 py-4 wow flipInY" data-wow-delay="1s">
                            <div className="referGlass">
                                <div className="row p-3 p-md-5 w-100 m-0">

                                    <div className="col-12 minusMrg">
                                        <img src="/images/ReferAndEarn_Image.png" className="img-fluid"
                                            alt="Refer And Earn Page Image" draggable="false" />
                                    </div>

                                    <div className="col-12 d-flex flex-column justify-content-around align-items-center pb-4">
                                        <div
                                            className="w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                                            <h1 className="pointData ms-lg-4 text-center">Task</h1>
                                        </div>
                                    </div>

                                    {
                                        tasks.map(item => {
                                            return <TaskCard data={item} setTasks={setTasks} />
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Task