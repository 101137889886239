import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { spin } from '../../api';
import toast from 'react-hot-toast';

const Spinner = () => {
    const numElements = 8; // Number of elements
    const initialSpinwheel = Array.from({ length: numElements }, (_, i) => i === 5 ? 'active' : false); // Initial state with 'active' at index 5
    const [betAmount, setBetAmount] = useState(0)
    const [spinwheel, setSpinwheel] = useState(initialSpinwheel);
    const [activeIndex, setActiveIndex] = useState(0);
    const [roundsCompleted, setRoundsCompleted] = useState(0);
    const [targetIndex, setTargetIndex] = useState(null);
    const [intervalStatus, setIntervalStatus] = useState(false)
    const checkactive = (value) => {
        return 'active'
    }
    const { user } = useSelector((state) => state.user)
    useEffect(() => {
        let interval = setInterval(() => {
            if (intervalStatus) {
                setSpinwheel(prevSpinwheel =>
                    prevSpinwheel.map((item, index) => index === activeIndex ? 'active' : false)
                );
                setActiveIndex(prevIndex => {
                    const nextIndex = (prevIndex + 1) % numElements;
                    if (nextIndex === 0) {
                        setRoundsCompleted(prevRounds => prevRounds + 1);
                    }
                    if (roundsCompleted >= 3 && nextIndex == targetIndex) {
                        setIntervalStatus(false)
                        console.log(targetIndex)
                        // Stop the interval when reaching the target index
                        console.log(roundsCompleted >= 3 && nextIndex == targetIndex);
                    }
                    return nextIndex;
                });
            }
        }, 100); // Change the element every half second

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [activeIndex, roundsCompleted, targetIndex, numElements, intervalStatus]);
    // useEffect(() => {

    //     const randomIndex = Math.floor(Math.random() * numElements);
    //     setTargetIndex(randomIndex);
    // }, [roundsCompleted, targetIndex, numElements, intervalStatus]);

    const spinHandler = async () => {
        try {
            if (betAmount > 3000) {
                if (user?.points < betAmount) {
                    toast.error('Insufficient points')
                    return
                }
                const { data } = await spin({ amount: betAmount });
                setRoundsCompleted(0); setIntervalStatus(true);
                setTargetIndex(data?.data == 7 ? 7 :data?.data + 1 || 1);
            } else {
                toast.error('Min Bet limit is 3000')
            }
        } catch (err) {
            toast.error(err?.response?.data?.message || err.message)
        }
    }


    return (
        <section className="mrg_tb">
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-6 position-relative d-flex justify-content-center align-items-center mrg_t "
                    >

                        <div className="row mainPaddspin">

                            <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                <div className="cusPaddOptionGift">
                                    <img src="/images/1_Options_Gift.png" className="img-fluid"
                                        alt="Mystery Box of ZK Realm Spinner" draggable="false" />
                                </div>
                                <img src={`/images/${spinwheel[0] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                    draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                            </div>

                            <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                <div className="cusPaddOptionTEXT">
                                    <img src="/images/Better_Luck_Next_Time.png" className="img-fluid"
                                        alt="Better Luck Next Time Option of ZK Realm Spinner" draggable="false" />
                                </div>
                                <img src={`/images/${spinwheel[1] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                    draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                            </div>

                            <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                <div className="cusPaddOptionGift">
                                    <img src="/images/2x.png" className="img-fluid" alt="2x Option of ZK Realm Spinner"
                                        draggable="false" />
                                </div>
                                <img src={`/images/${spinwheel[2] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                    draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                            </div>

                            <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                <div className="cusPaddOptionTEXT">
                                    <img src="/images/Free_Spin.png" className="img-fluid"
                                        alt="Free Spin Option of ZK Realm Spinner" draggable="false" />
                                </div>
                                <img src={`/images/${spinwheel[7] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                    draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                            </div>


                            <div className="col-4 p-0">
                                <img src="/images/Center_Button_of_Spinner.png" className="img-fluid w-100" draggable="false"
                                    alt="Center Button of Spinner Fot ZK Realm Spinner" />
                            </div>


                            <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                <div className="cusPaddOptionTEXT">
                                    <img src="/images/Free_NFT_Mint.png" className="img-fluid"
                                        alt="Free NFT Mint Option of ZK Realm Spinner" draggable="false" />
                                </div>
                                <img src={`/images/${spinwheel[3] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG" draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                            </div>

                            <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                <div className="cusPaddOptionTEXT">
                                    <img src="/images/Whitelist.png" className="img-fluid"
                                        alt="Whitelist Option of ZK Realm Spinner" draggable="false" />
                                </div>
                                <img src={`/images/${spinwheel[6] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                    draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                            </div>

                            <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                <div className="cusPaddOptionGift">
                                    <img src="/images/5x.png" className="img-fluid" alt="5x Option of ZK Realm Spinner"
                                        draggable="false" />
                                </div>
                                <img src={`/images/${spinwheel[5] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                    draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                            </div>

                            <div className="col-4 p-0 position-relative d-flex justify-content-center align-items-center">
                                <div className="cusPaddOptionGift">
                                    <img src="/images/1_Options_Gift.png" className="img-fluid"
                                        alt="Mystery Box of ZK Realm Spinner" draggable="false" />
                                </div>
                                <img src={`/images/${spinwheel[4] ? 'Active_' : ''}Options_Box_of_Spinner.png`} className="img-fluid h-100 optionsBG"
                                    draggable="false" alt="Options Box Background of ZK Realm Spinner" />
                            </div>

                        </div>

                        <img src="/images/Main_Frame_of_Spinner.png" className="img-fluid h-100 MainBGspin" draggable="false"
                            alt="Main Background Frame of ZK Realm Spinner" />
                        <img src="/images/Spin_Decor.png" className="img-fluid px-auto SpinDecor" draggable="false"
                            alt="Spinner Decoration images of ZK Realm Spinner" />
                    </div>

                    <div className="col-md-6 mt-4 mt-md-0">
                        <div className="totalPoints "><span
                            className="fs-theme cl-gt pe-lg-3 headTpoints">Total Points :</span> {user?.points || "00"}</div>
                        <input type="number" placeholder="Enter your spin amount here" value={betAmount}
                            className="cusInput w-100 my-3" data-wow-delay="1.4s" onChange={e => setBetAmount(e.target.value)} />
                        <a href="#" className="" data-wow-delay="1.6s" >
                            <div className="btnZkboom" onClick={e => spinHandler()}>ZK BOOM</div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Spinner