import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'counter',
    initialState: {
        user: {},
        contract: {}
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
        setContract: (state, action) => {
            state.contract = action.contract
        }
    },
})

export const { setUser, setContract } = userSlice.actions

export default userSlice.reducer