import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import Web3 from 'web3';
import { registerUser } from '../api';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setContract, setUser } from '../store/Slices/userSlice';
import ContractAbi from '../abi/abi.json'

const ConnectWallet = () => {
    const [walletAddress, setWalletAddress] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', handleAccountsChanged);
            window.ethereum.on('chainChanged', () => window.location.reload());

            return () => {
                if (window.ethereum.removeListener) {
                    window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
                    window.ethereum.removeListener('chainChanged', () => window.location.reload());
                }
            };
        }
    }, []);

    const handleAccountsChanged = (accounts) => {
        if (accounts.length === 0) {
            setErrorMessage("Please connect to MetaMask.");
            setWalletAddress(null);
        } else {
            setWalletAddress(accounts[0]);
            setErrorMessage(null);
        }
    };

    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                // Request account access if needed
                await window.ethereum.request({ method: 'eth_requestAccounts' });

                // Create a web3 instance
                const web3 = new Web3(window.ethereum);
                const ContractAddress = "0x50687E20bFbC3c90E32cc7e7ba9B3EbfFD230133"
                // Get the wallet address
                const accounts = await web3.eth.getAccounts();
                const contract = new web3.eth.Contract(ContractAbi, ContractAddress)
                const ref = searchParams.get('ref')
                const { data } = await registerUser({ walletAddress: accounts[0], ref })
                if (data.success) {
                    toast.success(data.msg)
                    localStorage.setItem('token', data?.data?.token)
                    dispatch(setUser(data?.data))
                }
                setErrorMessage(null);
            } catch (error) {
                console.log(error);
                setErrorMessage("An error occurred: " + error.message);
            }
        } else {
            setErrorMessage("MetaMask is not installed. Please install it to use this feature.");
        }
    };

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage)
            setTimeout(() => {
                setErrorMessage("")
            }, 3000);
        }
    }, [errorMessage])
    const defaultfunction = async () => {
        if (window.ethereum) {
            const web3_1 = new Web3(window.ethereum)
            const networkId = await web3_1.eth.net.getId()
            if (networkId == 300) {
                const accounts = await web3_1.eth.getAccounts()
                const ref = searchParams.get('ref')
                const { data } = await registerUser({ walletAddress: accounts[0], ref })
                if (data.success) {
                    localStorage.setItem('token', data?.data?.token)
                    dispatch(setUser(data?.data))
                }
                setErrorMessage(null);
            } else {
                toast.error("Please Switch To ZKSync Testnet")
            }
        }
    }
    useEffect(() => {
        defaultfunction()
    }, []);

    return (
        <div>
            <div className="cusBtn ms-3 ms-md-5" style={{ cursor: "pointer" }} onClick={connectWallet}>{user.walletAddress ? user?.walletAddress?.slice(0, 10) + '...' : "CONNECT WALLET"}</div>
        </div>
    );
};

export default ConnectWallet;
