import React, { useState } from 'react'
import ConnectWallet from './ConnectWallet'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Navbar = () => {
    const [navActive, setnavActive] = useState(false)
    const { user } = useSelector((state) => state.user)
    console.log(user)
    return (
        <>
            <header className="wow bounceInDown" data-wow-duration="1s">
                <div className="container">
                    <div className="d-flex justify-content-start align-items-center my-5">
                        <a href="#"><img src="/images/Logo.png" alt="Logo of Meta Beer Bottle" draggable="false" className="img-fluid logo" /></a>
                        <nav className="ms-auto">
                            <ul className={`navbar ${navActive && 'navbarActive'}`}>
                                <li onClick={e => setnavActive(false)} className="Close wow fadeIn" data-wow-delay="0.9s"><i className="far fa-times"></i></li>
                                <li className="nav wow fadeIn" data-wow-delay="1s"><Link to="/" className="navItem NavTxt ">Home</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.1s"><Link to="/dailyCheckin" className="navItem NavTxt ">Daily Check in</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.2s"><Link to="/task" className="navItem NavTxt ">Task</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.3s"><Link to="/spin" className="navItem NavTxt ">Spin</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.4s"><Link to="#" className="navItem NavTxt ">Bull Bear</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.5s"><Link to="/refer" className="navItem NavTxt ">Refer & Earn</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.6s"><Link to='/pointredeem' className="navItem NavTxt ">Point Redeem</Link></li>
                                <li className="nav wow fadeIn" data-wow-delay="1.7s"><Link to={'/leaderboard'} className="navItem NavTxt ">Leaderboard</Link></li>
                            </ul>
                        </nav>
                        {
                            user?.points &&
                            <div className="NavPoints d-flex justify-content-center align-items-center cl-t">
                                <div className="scorePoints"><img src="/images/White - Zk Realm Logo.png" className="img-fluid" draggable="false" alt="White color Logo of ZK RealM" /></div>
                                <span className="scorePointsText">{user?.points || "00"}</span>
                            </div>
                        }
                        <div className="blackBG"></div>
                        <ConnectWallet></ConnectWallet>
                        <div className="d-inline-block">
                            <div className="navIcon" onClick={e => setnavActive(!navActive)}><i className="fas fa-bars"></i></div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Navbar